import { template as template_75ecdc947b1445e8881a54c4c5b12fc4 } from "@ember/template-compiler";
const FKText = template_75ecdc947b1445e8881a54c4c5b12fc4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
