import { template as template_9e0d523848484547a0d9753c30933b39 } from "@ember/template-compiler";
const FKControlMenuContainer = template_9e0d523848484547a0d9753c30933b39(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
